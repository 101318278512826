@use 'styles/mixins';

.avatar {
  $size: 11rem;

  width: $size;
  height: $size;
  min-height: $size;
  border-radius: 50%;
  background: url('../../assets/images/avatar-180.jpg') no-repeat center;

  @include mixins.retina {
    background: url('../../assets/images/avatar-360.jpg') no-repeat center;
    background-size: 180px 180px;
  }
}
