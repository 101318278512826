@use 'styles/breakpoints';

.navigation {
  $self: &;

  visibility: hidden;
  position: fixed;
  max-width: 22rem;
  bottom: 1.5rem;
  right: 1.5rem;
  display: flex;
  align-items: center;
  color: var(--c-text-light);
  border-radius: 1.5rem;
  background-color: var(--c-nav-bg);
  z-index: 5;

  animation: navigation 0.5s ease 1s forwards;
  transition: all 0.2s ease-in-out;

  & * {
    box-sizing: border-box;
  }

  &__action {
    position: relative;
    width: 3rem;
    height: 3rem;
    padding: 1rem;
    cursor: pointer;
    z-index: 2;
  }

  &__content {
    position: relative;
    display: flex;
    align-items: center;
    padding-right: 0.5rem;
    padding-left: 1.25rem;
    opacity: 0;
    z-index: 1;

    &-wrapper {
      width: 0;
      will-change: width;
      transition: width 0.25s ease-in-out;
    }
  }

  &--expanded {
    #{$self}__content {
      opacity: 1;
      transition: opacity 0.25s ease-in 0.2s;

      &-wrapper {
        width: calc(100vw - 7rem);
      }
    }
  }

  @media all and (min-width: 25rem) {
    &__content {
      padding-left: 2rem;
    }
  }
}

@keyframes navigation {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }
}
