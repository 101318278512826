@use 'styles/mixins';
@use 'styles/breakpoints';

.certificate {
  flex-direction: column;
  color: var(--c-text-light);

  & ~ & {
    margin-top: 1.75rem;
  }

  &,
  &__year,
  &__content {
    display: flex;
  }

  &__year {
    padding: 0 0 0.125rem 1rem;
  }

  &__title {
    color: white;
    box-sizing: border-box;
    font-weight: 600;
    padding: 0 1rem;
  }

  &__year,
  &__description {
    color: var(--c-text-gold);
  }

  &__content {
    flex-grow: 1;
    align-items: baseline;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  &__description {
    padding-right: 1rem;
  }

  @media all and (min-width: 30rem) {
    flex-direction: row;
    align-items: center;

    &__year {
      width: 3rem;
      padding: 0.75rem 0;
    }
  }
}
