@use 'styles/mixins';

.skill {
  min-width: var(--s-skill-width);
  color: var(--c-text-light);
  letter-spacing: 0.03125rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-weight: 500;
  border-radius: 0.25rem;

  & ~ & {
    margin-top: 0.25rem;
  }
}
