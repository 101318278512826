$c-light: #f5f5f5;
$c-dark: #040404;
$c-gold: #fff4e5;

$c-primary-main: $c-dark;
$c-primary-light: #222222;
$c-primary-dark: #0b0b0b;

$c-error-main: #e53415;
$c-error-light: #f04a2c;
$c-error-dark: #ca2c10;

$c-bg-grey: #3c454c;
$c-bg-gold: #cfa277;
$c-bg-dark: $c-dark;

$c-button-bg: #262626;
$c-nav-bg: #1f2224;
$c-bg-overlay: rgba(255, 255, 255, 0.04);

:root {
  --c-text-main: #{$c-dark};
  --c-text-light: #{$c-light};
  --c-text-gold: #{$c-gold};

  --c-bg-light: #{$c-light};
  --c-bg-dark: #{$c-dark};
  --c-bg-grey: #{$c-bg-grey};
  --c-bg-gold: #{$c-bg-gold};

  --c-projects-bg: #{$c-light};
  --c-button-bg: #{$c-button-bg};
  --c-nav-bg: #{$c-nav-bg};

  --c-app-bg: #{$c-bg-dark};
  --c-bg-overlay: #{$c-bg-overlay};

  --c-layer-bg-main: rgb(242, 242, 242);
  --c-layer-bg-client: rgba(18, 125, 202, 0.6);
  --c-layer-bg-server: rgba(21, 164, 138, 0.6);
  --c-layer-bg-api: rgba(255, 144, 65, 0.6);
}

:export {
  textPrimary: $c-dark;

  primaryMain: $c-primary-main;
  primaryLight: $c-primary-light;
  primaryDark: $c-primary-dark;

  errorMain: $c-error-main;
  errorLight: $c-error-light;
  errorDark: $c-error-dark;
}
