@use 'styles/mixins';

.tech-stack {
  opacity: 0.65;

  &__title {
    font-size: 0.875rem;
    text-transform: uppercase;
    padding-bottom: 1rem;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    line-height: 1.75;
  }

  &__item {
    margin-right: 1rem;
  }
}

body[data-theme-mode='dark'] {
  .tech-stack {
    &__title {
      color: var(--c-text-light);
    }
  }
}
