@import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;600;700&display=swap');

$f-family: -apple-system, BlinkMacSystemFont, 'Maven Pro', 'Roboto',
  'Helvetica Neue', sans-serif;

body {
  font-family: $f-family;
}

:export {
  fontFamily: #{$f-family};
}
