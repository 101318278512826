@use 'styles/breakpoints';
@use 'styles/mixins';

.hero {
  $self: &;
  @include mixins.flex-col-center;

  min-height: calc(100vh + 2rem);
  padding-top: 4rem;
  padding-bottom: 6rem;
  background-color: var(--c-bg-light);

  &__col {
    @include mixins.flex-col-center;
    transform: translateY(-2rem);
  }

  &__avatar {
    animation: fade 0.35s ease-in 0.25s both;
  }

  &__title {
    padding-top: 1.75rem;
    padding-bottom: 0.5rem;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    animation: fade 0.35s ease-in 0.4s both;
  }

  &__description {
    text-align: center;
    animation: fade 0.5s ease-in 0.6s both;

    p {
      line-height: 1.75;
    }
  }

  &__app {
    width: 6rem;
    height: 20rem;
    margin-top: 8rem;
    animation: fade 0.5s ease-in-out 0.8s both;

    .app-layer {
      &__image {
        width: 6rem;
        height: 11rem;
        border-radius: 0.5rem;
      }
    }
  }

  &.anim {
    .hero__app {
      .app-layers {
        transform: translate(-6rem, 4rem);
      }
    }
  }

  @include breakpoints.md {
    flex-direction: row;

    &__col {
      width: 20rem;
    }

    &__title {
      padding-bottom: 0.75rem;
    }

    &__description {
      margin-bottom: 2rem;
    }
  }

  @include breakpoints.lg {
    &__col {
      width: 22rem;
    }
  }
}
