@use 'styles/breakpoints';
@use 'styles/mixins';

.project {
  $self: &;
  @include mixins.flex-col-center;

  position: relative;
  max-width: 40rem;
  padding-bottom: 6rem;
  color: var(--c-text-light);

  & ~ & {
    margin-top: 10rem;
  }

  &__column {
    position: relative;
    z-index: 2;

    &.description {
      @include mixins.flex-col-center;
    }
  }

  &__app-view {
    overflow: hidden;
    box-sizing: border-box;
    width: var(--s-app-view-width);
    height: var(--s-app-view-height);
    border-radius: 1rem;
    background-color: var(--c-bg-default);
    box-shadow: 0 0 3rem 0rem rgba(0, 0, 0, 0.75);

    &-container {
      position: relative;
    }
  }

  &__icon-title {
    @include mixins.flex-col-center;
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  &__icon {
    width: 72px;
    height: 72px;
    padding-bottom: 1rem;
  }

  &__link {
    @include mixins.flex-col-center;
  }

  &__title {
    padding-top: 1.125rem;
  }

  &__description {
    padding: 0 2rem 3rem;
    font-weight: 500;
    line-height: 1.75;
  }

  &__stack {
    width: 100%;
  }

  .tech-stack {
    padding: 0 2rem;
  }

  @include breakpoints.md {
    min-width: 42rem;
    padding-bottom: 6rem;
  }

  @include breakpoints.lg {
    min-width: 55rem;
    padding-left: 6rem;
    padding-bottom: 12rem;
    flex-direction: row;
    justify-content: space-evenly;

    &__column.description {
      align-items: flex-start;
      padding-left: 8rem;
    }

    &__icon-title {
      padding: 0;
    }

    &__link {
      flex-direction: row;
    }

    &__icon {
      padding: 0;
    }

    &__title {
      padding-top: 0;
      padding-left: 1.5rem;
    }

    &__description,
    .tech-stack {
      padding: 3rem 0 0;
    }

    &__column.description {
      padding-left: 10rem;
    }
  }

  @include breakpoints.xl {
    min-width: 70rem;
    padding-left: 8rem;

    &__column.description {
      padding-left: 12rem;
    }
  }
}
