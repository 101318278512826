@use 'styles/mixins';

.contact {
  @include mixins.flex-col-center;

  background-color: #efefef;

  &-form {
    &__wrapper {
      box-sizing: border-box;
      width: 100%;
      padding-right: 2rem;
      padding-left: 2rem;
      text-align: center;
    }

    &__fields-wrapper {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }
}
