.contact-form {
  &__wrapper {
    max-width: 40rem;
  }

  &__header {
    h2 {
      font-size: 2rem;
      font-weight: 600;
      line-height: 1.25;
    }

    p {
      padding-top: 1.5rem;
      padding-bottom: 1rem;
    }
  }
}
