@use 'styles/breakpoints';
@use 'styles/mixins';

.projects {
  @include mixins.flex-col-center;

  padding-top: 12rem;
  background-color: var(--c-bg-dark);

  @include breakpoints.lg {
    padding-top: 15rem;
    padding-bottom: 10rem;
  }
}
