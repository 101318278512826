@use 'styles/breakpoints';
@use 'styles/mixins';

.skills {
  @include mixins.flex-col-center;
  @include mixins.section--shifted;

  background-color: var(--c-bg-grey);

  &__group {
    &-wrapper {
      box-sizing: border-box;
      transform: translate(0.75rem);
    }

    & ~ & {
      margin-top: 2rem;
    }

    .skill__title {
      padding-left: 1rem;
      border-left: 0.25rem solid #798893;
    }

    &:nth-child(1) .skill__title {
      border-left: 0.25rem solid #2589d5;
    }

    &:nth-child(2) .skill__title {
      border-left: 0.25rem solid #d29e49;
    }

    &:nth-child(3) .skill__title {
      border-left: 0.25rem solid #2ba28c;
    }
  }

  @include breakpoints.md {
    &__group-wrapper {
      width: 36rem;
      transform: translate(3rem);

      -moz-column-count: 2;
      -webkit-column-count: 2;
      column-count: 2;
    }

    &__group {
      & ~ & {
        margin-top: 3rem;
      }
    }
  }

  @include breakpoints.lg {
    &__group-wrapper {
      width: 42rem;
      // transform: translate(4rem);
    }

    &__group {
      & ~ & {
        margin-top: 4rem;
      }
    }
  }
}
