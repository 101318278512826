.carousel-item {
  display: flex;
  overflow: hidden;
  box-sizing: border-box;
  width: var(--s-app-view-width);
  height: var(--s-app-view-height);
  border-radius: 1rem;
  border: 3px solid rgba(255, 255, 255, 0.8);
  background-color: var(--c-bg-default);

  &--current {
    position: relative;
    // opacity: 0 !important;
    cursor: pointer;
    box-shadow: 0 0 3rem rgba(0, 0, 0, 0.5);
    z-index: 3;

    &.anim {
      animation: anim-current 0.35s ease both;
    }
  }

  &--prev {
    opacity: 0.3;
    filter: blur(4px);
    transform: scale(0.5) rotate(-11deg);
  }

  &--next {
    opacity: 0.5;
    filter: blur(2px);
    transform: scale(0.7) rotate(14deg);
  }

  &--prev,
  &--next {
    &.anim {
      animation-duration: 0.35s;
      animation-timing-function: ease;
      animation-fill-mode: both;
    }
  }

  &--prev.anim {
    animation-name: anim-prev;
  }

  &--next.anim {
    animation-name: anim-next;
  }
}

@keyframes anim-current {
  0% {
    opacity: 0;
    transform: scale(0) translate(0) rotate(0);
  }
  5% {
    opacity: 0;
    transform: scale(0.7) translate(7rem) rotate(9deg);
  }
  100% {
    opacity: 1;
    transform: scale(1) translate(0) rotate(0);
  }
}

@keyframes anim-prev {
  0%,
  100% {
    opacity: 0.3;
    transform: scale(0.5) translate(0) rotate(-11deg);
  }
  75% {
    opacity: 0;
    transform: scale(0.7) translate(10rem) rotate(0);
  }
  80% {
    opacity: 0;
    transform: scale(0.35) translate(-5rem) rotate(-11deg);
  }
}

@keyframes anim-next {
  0%,
  100% {
    opacity: 0.5;
    transform: scale(0.7) translate(0) rotate(14deg);
  }
  40% {
    opacity: 0;
    transform: scale(0.8) translate(-4rem) rotate(3deg);
  }
  42% {
    opacity: 0;
    transform: scale(0.2) translate(-5rem) rotate(0);
  }
}
