.app-layers {
  position: relative;
  width: var(--s-app-layer-width);
  height: var(--s-app-layer-height);
  transform: translateY(-4.75rem);
  opacity: 0;
  transition: transform 0.75s, opacity 0.25s;
  transition-timing-function: ease-in-out;

  .app-layer {
    position: absolute;
    transition: transform 0.75s ease-in-out;

    &:nth-child(1) {
      z-index: 10;
    }
    &:nth-child(2) {
      z-index: 9;
    }
    &:nth-child(3) {
      z-index: 8;
    }
    &:nth-child(4) {
      z-index: 7;
    }
    &:nth-child(5) {
      z-index: 6;
    }
    &:nth-child(6) {
      z-index: 5;
    }
    &:nth-child(7) {
      z-index: 4;
    }
    &:nth-child(8) {
      z-index: 3;
    }
  }

  &:hover,
  &--expand {
    opacity: 1;
    transform: translate(-7rem, 4rem);

    .app-layer {
      &__text-box {
        transform: translateY(0);
        opacity: 1;
        transition: transform 0.5s, opacity 0.5s;
        transition-timing-function: ease-in-out;
        transition-delay: 0.25s;
      }

      &:nth-child(1) {
        transform: translate(2rem, -1.5rem);
      }
      &:nth-child(2) {
        transform: translate(4rem, -3rem);
      }
      &:nth-child(3) {
        transform: translate(6rem, -4.5rem);
      }
      &:nth-child(4) {
        transform: translate(8rem, -6rem);
      }
      &:nth-child(5) {
        transform: translate(10rem, -7.5rem);
      }
      &:nth-child(6) {
        transform: translate(12rem, -9rem);
      }
    }
  }
}
