.app-layer {
  $self: &;

  &__image {
    width: var(--s-app-layer-width);
    height: var(--s-app-layer-height);
    border: 1px solid rgba(255, 255, 255, 0.15);

    img {
      animation: fade 0.5s ease-in-out 1.5s both;
    }
  }

  &__text {
    transform: translate(-0.1rem, -1.75rem);

    &-box {
      padding-bottom: 1.5rem;
      margin-bottom: 0.5rem;
      border-left: 1px solid rgba(17, 59, 96, 0.1);
      transform: translateY(3rem);
      opacity: 0;
    }
  }

  &.client {
    #{$self}__image {
      background-color: var(--c-layer-bg-client);
    }
  }
  &.server {
    #{$self}__image {
      background-color: var(--c-layer-bg-server);
    }
  }
  &.api {
    #{$self}__image {
      background-color: var(--c-layer-bg-api);
    }
  }
}
