%flex {
  display: flex;
}

%flex-col {
  @extend %flex;
  flex-direction: column;
}

%centered {
  align-items: center;
  justify-content: center;
}

@mixin section--shifted() {
  transform: translateY(-2rem);
  margin-bottom: -2rem;
  border-radius: 2rem;
  z-index: 2;
}

@mixin flex($justify-content: flex-start, $align-items: flex-start) {
  @extend %flex;
  align-items: $align-items;
  justify-content: $justify-content;
}

@mixin flex-col($justify-content: flex-start, $align-items: flex-start) {
  @extend %flex-col;
  align-items: $align-items;
  justify-content: $justify-content;
}

@mixin flex-center {
  @extend %flex;
  @extend %centered;
}

@mixin flex-col-center {
  @extend %flex-col;
  @extend %centered;
}

/* solves sticky problem */
@mixin hover {
  @media (hover: hover) {
    @content;
  }
}

@mixin retina {
  @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
    @content;
  }
}
