@use 'styles/breakpoints';

.carousel {
  &__items-container,
  &__bg {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 50%;
  }

  &__items-container {
    display: flex;
    top: 1rem;
    width: 23rem;
    height: 23rem;
    transform: translate(-11.5rem);
    z-index: 2;
    border-radius: 50%;
    opacity: 0.8;
  }

  &__bg {
    display: none;
  }

  @include breakpoints.sm {
    &__items-container {
      top: 0.5rem;
      width: 27rem;
      height: 26rem;
      transform: translate(-13.5rem);
    }
  }

  @include breakpoints.lg {
    &__bg {
      display: block;
      top: -3.5rem;
      width: 40rem;
      height: 36rem;
      transform: translate(-13rem) rotate(8deg);
      z-index: 1;
      opacity: 0.12;
      border-top-right-radius: 50%;
      border-bottom-right-radius: 70%;
      border-bottom-left-radius: 50%;
      border-top-left-radius: 70%;
    }
  }
}
