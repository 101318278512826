@use 'styles/mixins';

.certificates {
  @include mixins.flex-col-center;
  @include mixins.section--shifted;

  padding-top: 10rem;
  background-color: var(--c-bg-gold);

  &:before,
  &:after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    content: '';
  }

  &:before {
    background-color: var(--c-bg-light);
    z-index: 1;
    height: 2rem;
  }

  &:after {
    height: 4rem;
    border-radius: 2rem;
    background-color: var(--c-bg-gold);
    z-index: 2;
  }

  &__icon {
    transform: translateY(-4rem);
  }
}
